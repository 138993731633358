import { isNumber, set } from 'lodash';
import React, { useEffect } from 'react';
import './Main.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import Audit from './Application/Audit';
import Review from './Application/Review';
import { updateWithDbWorkflowList } from '../../reducers/user';
import useShowErrorAlert, { useFormatPerformanceAnalyticsData } from '../../utils/lib';
import AlertCode from '../../constants/alertCodes';
import getWorkflows from '../../api/workflow';
import NewApplication from './Application/NewApplication';
import CredentialSelectWrapper from '../Common/Credentials/CredentialSelectWrapper';
import errorCode from '../../constants/errorCode';
import { PERFORMANCE_METRIC_EVENTS } from '../../config';

function Application() {
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const { startTime, endTime } = useSelector((state) => state.data.application?.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const showErrorAlert = useShowErrorAlert();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();

  const fetchWorkflows = async () => {
    const worklowFetchStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/workflow',
      metaData: {
        appId: currentAppId,
      },
    };
    try {
      const workflowList = await getWorkflows(currentAppId, true, startTime, endTime);
      set(eventObj, 'statusCode', 200);
      dispatch(updateWithDbWorkflowList(workflowList));
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(`${errorCode.APPLICATION_ERROR_FETCHING_WORKFLOW} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_WORKFLOWS,
        },
      });
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_WORKFLOWS });
    }
    const processingTime = performance.now() - worklowFetchStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_GET_DB_WORKFLOWS;
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  useEffect(() => {
    fetchWorkflows();
  }, [currentAppId, startTime, endTime]);

  return (
    <div id="main__container__body__generic">
      <div className="application__container">
        <div className="application__subheading_container">
          <div className="application__subheading_appId_container">
            <span className="application__subheading">
              Applications
            </span>
            <CredentialSelectWrapper className="application__creds" />
          </div>
          <div>
            <NewApplication />
          </div>
        </div>
        {location.pathname === '/review' ? <Review /> : <Audit />}
      </div>
    </div>
  );
}

export default Application;
