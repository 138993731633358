import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Flags from './Flags';
import Header from './Header';
import SdkError from './SdkError';

function ReviewCard({
  transactionData, selectedModule, handleModuleChange,
}) {
  const flagData = useSelector((state) => state.appDetails.flagData);
  const v2FlagData = useSelector((state) => state.appDetails.v2FlagData);

  const { status, sdkError } = transactionData;
  return (
    <div id="right_panel__review_card__container">
      <Header status={status} />
      { status === 'error'
        ? <SdkError errorMsg={sdkError} />
        : (
          <Flags
            flags={flagData}
            v2Flags={v2FlagData}
            selectedModule={selectedModule}
            handleModuleChange={handleModuleChange}
          />
        )}
    </div>
  );
}

ReviewCard.propTypes = {
  transactionData: PropTypes.object.isRequired,
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};

export default ReviewCard;
