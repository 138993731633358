const constants = {
  maxRecordsInTable: 10,
  maxRecordInAMLTable: 5,
  analyticsRefreshTime: 10 * 60 * 1000, // 10 mins expiry time for url
  resendOTPTimeInSeconds: 60,
};

const allowedPermissions = {
  AUDIT: 'audit',
  REVIEW: 'reviewPortal',
};

const DOCUMENTATION_PORTAL_URL = process.env.REACT_APP_DOCUMENTATION_PORTAL_URL || 'https://documentation.hyperverge.co';

const DEPRECATED_DOCUMENTATION_PORTAL_URL = 'https://developer.hyperverge.co';

const DEFAULT_BILLING_TYPE = 'tax';

const PERFORMANCE_METRIC_EVENTS = {
  APPLICATION_PAGE_GET_DB_WORKFLOWS: 'APPLICATION_PAGE_GET_DB_WORKFLOWS',
  FETCH_WORKFLOW: 'FETCH_WORKFLOW',
  APPLICATION_PAGE_FETCH_TABLE_DATA: 'APPLICATION_PAGE_FETCH_TABLE_DATA',
  APPLICATION_PAGE_FETCH_ROW_COUNT: 'APPLICATION_PAGE_FETCH_ROW_COUNT',
  APPLICATION_PAGE_CHANGE: 'APPLICATION_PAGE_CHANGE',
  APPLICATION_PAGE_FILTER_CHANGE: 'APPLICATION_PAGE_FILTER_CHANGE',
  APPLICATION_PAGE_FETCH_FILTER_CONFIG: 'APPLICATION_PAGE_FETCH_FILTER_CONFIG',
  APPLICATION_PAGE_FETCH_TRANSACTION_METADATA_CONFIG: 'APPLICATION_PAGE_FETCH_TRANSACTION_METADATA_CONFIG',
  APPLICATION_PAGE_GENERATE_APPLICATION_LINK: 'APPLICATION_PAGE_GENERATE_APPLICATION_LINK',
  APPLICATION_PAGE_DOWNLOAD_CSV: 'APPLICATION_PAGE_DOWNLOAD_CSV',
  APPLICATION_LIST_LOAD_PREVIOUS_RECORD: 'APPLICATION_LIST_LOAD_PREVIOUS_RECORD',
  APPLICATION_LIST_LOAD_NEXT_RECORD: 'APPLICATION_LIST_LOAD_NEXT_RECORD',
  APPLICATION_LIST_APPROVE_CLICK: 'APPLICATION_LIST_APPROVE_CLICK',
  APPLICATION_LIST_DECLINE_CLICK: 'APPLICATION_LIST_DECLINE_CLICK',
  APPLICATION_DETAILS_PAGE_INIT_FETCH: 'APPLICATION_DETAILS_PAGE_INIT_FETCH',
  DEVHUB_CREDENTIAL_FETCH: 'DEVHUB_CREDENTIAL_FETCH',
  DEVHUB_FETCH_CREDENTIAL_ERROR: 'DEVHUB_FETCH_CREDENTIAL_ERROR',
  AUDIT_DATA_DOWNLOAD: 'AUDIT_DATA_DOWNLOAD',
};

const AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME = 'audit_portal_fe_performance_monitoring';

module.exports = {
  constants,
  allowedPermissions,
  DOCUMENTATION_PORTAL_URL,
  DEPRECATED_DOCUMENTATION_PORTAL_URL,
  DEFAULT_BILLING_TYPE,
  PERFORMANCE_METRIC_EVENTS,
  AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
};
