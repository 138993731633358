import './DefaultOnboard.scss';
import React, {
  useState, useEffect,
} from 'react';
import * as Sentry from '@sentry/react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assests/images/logoPlain.png';
import alert from '../../assests/icons/alert-v2.svg';
import { useFormatAnalyticsData } from '../../utils/lib';
import {
  updateEmail, updateIsAuthenticated,
} from '../../reducers/user';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import authenticateUserAndHandleRedirect, { IconLeft, redirectFunction, startGoogleLogin } from './authHelper';
import SubmitButton from '../Common/Buttons/SubmitButton';
import PasswordInput from '../Common/Inputs/PasswordInput';
import CustomLoaderButton from '../Common/Buttons/CustomLoaderButton';
import ShakeContainer from './ShakeContainer';

export default function Login() {
  const formatAnalyticsData = useFormatAnalyticsData();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForGoogleLogin, setIsLoadingForGoogleLogin] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const isDisabled = email === '' || password === '' || isLoading;

  const clientId = useSelector((state) => state.user.clientId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openInputAlert, setOpenInputAlert] = useState(false);
  const auth = useSelector((state) => state.user.auth);
  const queryParams = new URLSearchParams(window.location.search);
  const [isRudderStackDataSent, setIsRudderStackDataSent] = useState(false);
  const [isRudderStackDataSentDoc, setIsRudderStackDataSentDoc] = useState(false);

  const handleSubmit = async (event) => {
    setIsGoogleLogin(false);
    setOpenInputAlert(false);
    event.preventDefault();
    if (isDisabled) return;
    setIsLoading(true);
    formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_LOGIN_CLICK, 'Login');
    event.preventDefault();
    dispatch(updateEmail(email));
    await authenticateUserAndHandleRedirect(
      dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
    );
    setIsLoading(false);
  };

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const navigateToForgotPassword = () => navigate('/password');

  const googleLogin = async () => {
    setIsGoogleLogin(true);
    setIsLoadingForGoogleLogin(true);
    await startGoogleLogin(navigate, dispatch, setErrorMessage,
      setOpenInputAlert, setIsLoadingForGoogleLogin, queryParams);
  };

  useEffect(() => {
    document.cookie.split(';').forEach((c) => {
      if (c.startsWith('authToken') || c.startsWith('refreshToken')) {
        const cookiName = c.split('=')[0];
        document.cookie = `${cookiName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
    });
  }, []);

  useEffect(() => {
    if (queryParams.has('redirect') && !isRudderStackDataSentDoc) {
      formatAnalyticsData(email, clientId, rudderstackEvents.DOCUMENTATION_LOGIN_LAUNCH, 'Login');
      setIsRudderStackDataSentDoc(true);
    } else if (!isRudderStackDataSent) {
      formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_LOGIN_LAUNCH, 'Login');
      setIsRudderStackDataSent(true);
    }
  }, [auth, navigate, queryParams]);

  useEffect(() => {
    if (queryParams.get('status') === 'error') {
      setOpenInputAlert(true);
      const errCode = queryParams.get('code') || 'unhandled-error';
      const errMessage = queryParams.get('message') || 'Something went wrong';
      if (errCode === 'user/not-found') setErrorMessage('Account does not exist. Choose another account or contact admin');
      else setErrorMessage(errMessage);
      dispatch(updateIsAuthenticated(false));
      Sentry.captureException(`${errCode} - ${errMessage}`, {
        extra: {
          errorMessage: errMessage,
        },
      });
      navigate(window.location.pathname);
    }
  }, [queryParams]);

  useEffect(() => {
    if (auth && !isGoogleLogin && queryParams.get('status') !== 'error') redirectFunction(dispatch, navigate, auth, queryParams);
  }, [auth]);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />

      <ShakeContainer isError={openInputAlert}>
        <h2 className="sign-in-font">Sign In to HyperVerge</h2>
        <div className="column-display">
          <CustomLoaderButton
            isLoading={isLoadingForGoogleLogin}
            onClick={googleLogin}
            Icon={IconLeft}
            buttonText="Sign In with Google"
          />
        </div>
        <div className="row-display margin-20">
          <hr className="horizontal-line" />
          <span className="or-text">or</span>
          <hr className="horizontal-line" />
        </div>
        <div className="column-display">
          <input
            data-hj-suppress
            id="input-box"
            type="text"
            placeholder="Email Address"
            onChange={handleEmailChange}
          />
          <PasswordInput
            id="input-box"
            className="margin-top-bottom"
            handlePasswordChange={handlePasswordChange}
            placeholder="Password"
            autoComplete="on"
            onKeyDown={handleEnterPress}
          />
          <button type="button" onClick={navigateToForgotPassword} className="forgot-password">
            Forgot Password?
          </button>
        </div>
        <SubmitButton
          isValid={!isDisabled}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          buttonText="Continue"
        />
        {openInputAlert && (
          <div className="invalid-field display-center">
            <img className="alert-icon" src={alert} alt="!" />
            <span>{errorMessage}</span>
          </div>
        )}
      </ShakeContainer>
    </div>
  );
}
