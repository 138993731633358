/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { styled, Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import QRCode from 'qrcode.react';
import axios from 'axios';
import './Home.scss';
import * as Sentry from '@sentry/react';
import close from '../../../assests/icons/close.png';
import useShowErrorAlert, { useFormatAnalyticsData } from '../../../utils/lib';
import AlertCode from '../../../constants/alertCodes';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import SingleSearchSelect from '../../Common/Inputs/SingleSearchSelect';
import linkKycStart from '../../../api/linkKyc';
import CredentialSelectWrapper from '../../Common/Credentials/CredentialSelectWrapper';
import errorCode from '../../../constants/errorCode';

function TabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(7, 8, 23, 0.85) ;
  -webkit-tap-highlight-color: transparent;
`;

export default function DemoModal({ open, closePopupWindow }) {
  const showErrorAlert = useShowErrorAlert();
  const formatAnalyticsData = useFormatAnalyticsData();
  const [value, setValue] = useState(0);
  const [emailSentWeb, setEmailSentWeb] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSentMobile, setEmailSentMobile] = useState(false);
  const [link, setLink] = useState('');
  const [currentWorkflowName, setCurrentWorkflowName] = useState('');
  const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const userEmail = useSelector((state) => state.user.email);
  const freshchatInitialized = useSelector((state) => state.initialize.freshchatInitialized);
  const clientId = useSelector((state) => state.user.clientId);
  const workflowList = useSelector((state) => state.user.workflowList);

  const mobileLink = 'https://hyperverge.app.link';

  const generateDemoLink = async () => {
    if (currentWorkflowId) {
      setLink('');
      try {
        const transactionId = `${(Math.random().toString(36).slice(2))}-DEMO`;
        const newLink = await linkKycStart(currentAppId, currentWorkflowId, transactionId);
        setLink(newLink);
        setEmailSentWeb(false);
      } catch (error) {
        const { response } = error;
        if (response && response.status && response.status === 400) {
          Sentry.captureException(`${errorCode.HOME_INVALID_TRANSACTION_ID} - ${error}`, {
            extra: {
              errorMessage: AlertCode.DUPLICATE_TRANSACTIONID_LINK,
            },
          });
          showErrorAlert({ error, message: AlertCode.DUPLICATE_TRANSACTIONID_LINK });
        } else {
          Sentry.captureException(`${errorCode.HOME_GENERATE_LINK_ERROR} - ${error}`, {
            extra: {
              errorMessage: AlertCode.ERROR_GENERATING_LINK,
            },
          });
          showErrorAlert({ error, message: AlertCode.ERROR_GENERATING_LINK });
        }
      }
    } else if (!workflowList && workflowList.length) {
      showErrorAlert({ alertSeverity: 'error', message: AlertCode.NO_WORKFLOWS });
    }
    // TODO : Merge generating new link functions present here and in ResponseModal
  };

  const sendEmail = async (isMobile = false) => {
    try {
      let linkToBeSent = link;
      let typeTobeSent = 'LINK';
      if (isMobile) {
        formatAnalyticsData(userEmail, clientId, rudderstackEvents.DASHBOARD_HOME_MOBILEDEMO_SENDEMAIL_CLICK, 'Home');
        linkToBeSent = mobileLink;
        typeTobeSent = 'MOBILE_LINK';
      } else {
        formatAnalyticsData(userEmail, clientId, rudderstackEvents.DASHBOARD_HOME_WEBDEMO_SEND_EMAIL_CLICK, 'Home');
      }
      let userName = userEmail.split('@');
      if (userName[0].split('.').length > 0) {
        [userName] = userName[0].split('.');
      }
      setSendingEmail(true);
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard/email`,
        headers: { appId: currentAppId },
        data: {
          email: userEmail,
          type: typeTobeSent,
          userName,
          link: linkToBeSent,
          isMobile,
          workflowId: currentWorkflowId,
        },
      });
      setSendingEmail(false);
      if (isMobile) {
        setEmailSentMobile(true);
      } else {
        setEmailSentWeb(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Sentry.captureException(`${errorCode.HOME_EMAIL_SENDING_LIMIT_EXCEEDED} - ${error}`, {
          extra: {
            errorMessage: AlertCode.EMAIL_SENDING_LIMIT_EXCEEDED,
          },
        });
        showErrorAlert({ error, message: AlertCode.EMAIL_SENDING_LIMIT_EXCEEDED });
      } else {
        Sentry.captureException(`${errorCode.HOME_ERROR_IN_SEND_MAIL} - ${error}`, {
          extra: {
            errorMessage: AlertCode.ERROR_IN_SENDING_EMAIL,
          },
        });
        showErrorAlert({ error, message: AlertCode.ERROR_IN_SENDING_EMAIL });
      }
    }
  };

  const closeModal = () => {
    closePopupWindow();
    setEmailSentWeb(false);
    setEmailSentMobile(false);
    if (freshchatInitialized) {
      window.fcWidget.show();
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleWorkflowSelect = (workflowId) => {
    const currentWorkflow = workflowList.filter((workflow) => workflow.id === workflowId);
    setCurrentWorkflowId(workflowId);
    setCurrentWorkflowName(currentWorkflow[0].name || currentWorkflow[0].id);
  };

  useEffect(() => {
    if (open) generateDemoLink();
  }, [open, currentWorkflowId]);

  return (
    <StyledModal
      open={open}
      components={
        { Backdrop }
      }
      onBackdropClick={closeModal}
      disableAutoFocus
    >
      <Box id="demo_modal__container">
        <div>
          <Grid container>
            <Grid item xs={11}>
              <h2 id="demo_modal__heading">
                How do you want to test?
              </h2>
            </Grid>
            <Grid item xs={1}>
              <img id="demo_modal__cancel_icon" src={close} alt="close" onClick={closeModal} />
            </Grid>
          </Grid>
        </div>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#335aff',
                  height: '1px',
                },
              }}
            >
              <Tab id={value ? 'demo_modal__web_tab' : 'demo_modal__web_tab--selected'} label="Browser" />
              <Tab id={value ? 'demo_modal__mobile_tab--selected' : 'demo_modal__mobile_tab'} label="Mobile App" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {
              clientId === 'HV'
              && (
              <div className="demo_modal__cred_wrapper">
                <CredentialSelectWrapper className="demo_modal__creds" />
              </div>
              )
            }
            <div id="demo_modal__workflow_select_container">
              <p>Workflow Selected:</p>
              <SingleSearchSelect
                value={currentWorkflowName}
                onValueChange={handleWorkflowSelect}
                optionList={workflowList}
                placeholder="Search for workflows..."
                emptyText="No workflow found"
                optionValueKey="id"
                optionNameKey="name"
              />
            </div>
            <p id="demo_modal__web_description_1">Open the below link to start verification</p>
            <Grid container>
              <Grid item xs={6}>
                <a
                  id="demo_modal__link"
                  target="_blank"
                  href={link}
                  rel="noreferrer"
                  onClick={() => {
                    formatAnalyticsData(userEmail, clientId, rudderstackEvents.DASHBOARD_HOME_WEBDEMO_LINK_OPEN, 'Home');
                  }}
                >
                  {link.slice(8)}
                </a>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={generateDemoLink} id="demo_modal__new_link_button">Get New link</Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => { sendEmail(false); }}
                  id={emailSentWeb ? 'demo_modal__email_sent_button' : 'demo_modal__email_button'}
                >
                  {sendingEmail ? 'Sending Email'
                    : (emailSentWeb && !sendingEmail) ? 'Email was sent' : 'Send link on email'}
                  <span className="tooltiptext">{userEmail}</span>
                </Button>
              </Grid>
            </Grid>
            <p style={{ textAlign: 'center' }}> -- or -- </p>
            <p id="demo_modal__web_description_2">Scan the QR below to start on your mobile browser</p>
            {link === '' ? <div style={{ textAlign: 'center', color: 'gray' }}>Generating Link...</div>
              : <QRCode value={link} style={{ marginLeft: '40%', marginTop: '20px' }} />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <p id="demo_modal__mobile_description_1">Open the below link to install mobile app</p>
            <Grid container>
              <Grid item xs={5}>
                <a
                  id="demo_modal__link_mobile"
                  target="_blank"
                  href="https://hyperverge.app.link"
                  rel="noreferrer"
                  onClick={() => {
                    formatAnalyticsData(userEmail, clientId, rudderstackEvents.DASHBOARD_HOME_MOBILEDEMO_LINK_OPEN, 'Home');
                  }}
                >
                  {mobileLink}
                </a>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => { sendEmail(true); }}
                  id={emailSentMobile ? 'demo_modal__email_sent_button' : 'demo_modal__email_button'}
                >
                  {sendingEmail ? 'Sending Email'
                    : (emailSentMobile && !sendingEmail) ? 'Email was sent' : 'Send link on email'}
                  <span className="tooltiptext">{userEmail}</span>
                </Button>
              </Grid>
            </Grid>
            <p style={{ textAlign: 'center' }}> -- or -- </p>
            <p id="demo_modal__mobile_description_2">Scan the QR below to download the app (both iOS &amp; Android)</p>
            <QRCode value="hyperverge.app.link" style={{ marginLeft: '40%', marginTop: '20px' }} />
          </TabPanel>
        </Box>
      </Box>
    </StyledModal>
  );
}

TabPanel.defaultProps = {
  children: '',
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

DemoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closePopupWindow: PropTypes.func.isRequired,
};
