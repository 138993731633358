import './DefaultOnboard.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import logo from '../../assests/images/logoPlain.png';
import alert from '../../assests/icons/alert-v2.svg';

import AlertCode from '../../constants/alertCodes';
import useShowErrorAlert, { useFormatAnalyticsData } from '../../utils/lib';
import { updateEmail } from '../../reducers/user';
import { isEmailValid } from '../../utils/helpers';
import SubmitButton from '../Common/Buttons/SubmitButton';
import ShakeContainer from './ShakeContainer';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import screenNames from '../../constants/screenNames';
import errorCode from '../../constants/errorCode';

export default function ResetPassword() {
  const showErrorAlert = useShowErrorAlert();
  const dispatch = useDispatch();
  const formatAnalyticsData = useFormatAnalyticsData();

  const [email, setEmail] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [errorObject, setErrorObject] = useState({ error: false });

  const navigate = useNavigate();
  const isDisabled = (email === '' || loading);

  const sendEmail = async () => {
    await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SELF_STAGING_URL}/api/user/passwordReset`,
      data: {
        email,
      },
    });
  };

  const handleSubmit = async (event) => {
    const validEmail = isEmailValid(email);
    setErrorObject({ error: !validEmail, message: 'Please enter a valid email address' });
    if (!validEmail || isDisabled) return;
    event.preventDefault();
    setIsLoading(true);
    try {
      await sendEmail();
      localStorage.setItem('password_reset_email', email);
      dispatch(updateEmail(email));
      navigate('/password/update');
    } catch (error) {
      if (error.response.status === 400 || error.response.data.message.includes('User does not exist')) {
        setErrorObject({ error: true, message: 'User account doesn\'t exist.' });
      }
      Sentry.captureException(`${errorCode.ONBOARDING_ERROR_IN_SENDING_MAIL} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_IN_SENDING_EMAIL,
        },
      });
      showErrorAlert({ error, message: AlertCode.ERROR_IN_SENDING_EMAIL });
    }
    setIsLoading(false);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleEmailChange = (event) => setEmail(event.target.value);

  const navigateToLogin = () => navigate('/');

  useEffect(() => {
    formatAnalyticsData('', '', rudderstackEvents.DASHBOARD_FORGOT_PASSWORD_CLICK, screenNames.LOGIN);
  }, []);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />
      <ShakeContainer isError={errorObject?.error}>
        <h2 className="forget-pass-font">Forgot Password?</h2>
        <p>Please enter your registered email, we will send recovery link to this</p>
        <div className="column-display">
          <input
            data-hj-suppress
            id="input-box"
            type="text"
            className="forget-pass-email-input"
            placeholder="Email Address"
            onChange={handleEmailChange}
            onKeyDown={handleEnterPress}
            required
          />
        </div>
        <SubmitButton
          isValid={!isDisabled}
          isLoading={loading}
          handleSubmit={handleSubmit}
          buttonText="Continue"
        />
        {errorObject?.error && (
          <div className="invalid-field display-center">
            <img className="alert-icon" src={alert} alt="!" />
            <span>{errorObject?.message}</span>
          </div>
        )}
        <div className="display-center">
          <button type="button" onClick={navigateToLogin} className="footer-text-reset-password">
            Back to login
          </button>
        </div>
      </ShakeContainer>
    </div>
  );
}
