import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { detect } from 'detect-browser';
import { useNavigate } from 'react-router-dom';
import AlertCode from '../constants/alertCodes';
import { updateUserLocation } from '../reducers/user';
import {
  showAlert, closeAlert, showToastNotification, closeToastNotification,
} from '../reducers/alert';
import storeAnalyticsMetadata from './rudderstackMetadata';
import store, { persistor } from '../store';

const useShowErrorAlert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showErrorAlert = async ({ error, message, onLoginError = false }) => {
    let alertMessage = message;
    if (error) {
      if (error.response) {
        if (onLoginError && (error.response.status === 401 || error.response.status === 500)) {
          navigate('/');
          dispatch({ type: 'RESET' });
        } else if (
          error.response.status === 401
          && error.response.data.error === 'Unauthorized - Missing loginToken'
        ) {
          alertMessage = AlertCode.LOGIN;
        }
      }
    }

    dispatch(showAlert({ alertSeverity: 'error', message: alertMessage }));
    setTimeout(() => {
      dispatch(closeAlert());
    }, 2000);
  };
  return showErrorAlert;
};

const useShowCustomAlert = () => {
  const dispatch = useDispatch();

  const showCustomAlert = ({ alertSeverity, message }) => {
    dispatch(showAlert({ alertSeverity, message }));
    setTimeout(() => {
      dispatch(closeAlert());
    }, 2000);
  };
  return showCustomAlert;
};
const useLogoutAction = () => {
  const showErrorAlert = useShowErrorAlert();
  const logout = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/logout`,
      });
    } catch (error) {
      Sentry.captureException(error);
      showErrorAlert({ error, message: AlertCode.SOMETHING_WENT_WRONG });
    } finally {
      await persistor.purge();
    }
  };
  return logout;
};

const useCloseAlertAction = () => {
  const dispatch = useDispatch();

  const closeAlertAction = () => {
    dispatch(closeAlert());
  };
  return closeAlertAction;
};

const useGetUserLocationDetails = () => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);

  const getUserLocationDetails = async () => {
    try {
      if (userLocation && Object.keys(userLocation).length) return userLocation;
      const res = await axios.get('https://ipapi.co/json/', { withCredentials: false });
      dispatch(updateUserLocation(res));
      return res;
    } catch (err) {
      Sentry.captureException(err);
      return err;
    }
  };

  return getUserLocationDetails;
};

const useFormatAnalyticsData = () => {
  const getUserLocationDetails = useGetUserLocationDetails();

  const formatAnalyticsData = async (email, clientId, eventName, currentScreen, applicationStatus = '') => {
    const browser = detect();
    const locDetails = await getUserLocationDetails();
    const rudderStackObj = {
      clientId,
      country: locDetails?.data?.country_name,
      ipAddress: locDetails?.data?.ip,
      device: navigator.platform,
      browser: browser.name,
      browserVersion: browser.version,
      eventName,
      userEmail: email,
      timeStamp: new Date().getTime(),
      currentScreen,
      product: 'dashboard',
    };
    if (applicationStatus !== '') {
      rudderStackObj.applicationStatus = applicationStatus;
    }
    storeAnalyticsMetadata(rudderStackObj);
  };

  return formatAnalyticsData;
};

const useFormatPerformanceAnalyticsData = () => {
  const getUserLocationDetails = useGetUserLocationDetails();

  const formatPerformanceAnalyticsData = async (eventData, eventName) => {
    const browser = detect();
    const locDetails = await getUserLocationDetails();
    const state = store.getState();
    const { role: userRole, email: userEmail, clientId: userClientId } = state.user;
    const eventDataObj = {
      ...eventData,
      userEmail,
      userClientId,
      ipAddress: locDetails?.data?.ip,
      device: navigator.userAgent || navigator.platform,
      browser: browser.name,
      browserVersion: browser.version,
      userRole,
      timestamp: new Date().toISOString(),
    };
    storeAnalyticsMetadata(eventDataObj, eventName, 'audit_portal_fe_performance_monitoring');
  };

  return formatPerformanceAnalyticsData;
};

const useShowToastNotification = () => {
  const dispatch = useDispatch();

  const showNotification = () => {
    dispatch(showToastNotification());
    setTimeout(() => {
      dispatch(closeToastNotification());
    }, 2000);
  };
  return showNotification;
};

const isUrl = (text) => {
  try {
    const url = new URL(text);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export default useShowErrorAlert;
export {
  useShowCustomAlert, useCloseAlertAction, useGetUserLocationDetails, useFormatAnalyticsData,
  useLogoutAction, useShowToastNotification, isUrl, useFormatPerformanceAnalyticsData,
};
